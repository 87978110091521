// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================

import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { useThemeUI } from 'theme-ui'

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================

import MultiLocations from '../components/MultiLocations/MultiLocations.js'
import SEO from '../components/Layout/seo'
import { store } from '../Context/Store'

// ?===================================
// ?====  Local Function Imports  =====
// ?===================================

// ?===================================
// ?========  Style Imports  ==========
// ?===================================
import '../../src/index.css'

// ?===================================
// ?======  Template Component  =======
// ?===================================

const MultipleLocationTemplate = props => {
  // ?===========================
  // ?===== Component State =====
  // ?===========================

  // const [themeMode, setThemeMode] = useColorMode('') // useColor must be used in a hook
  const {
    // globalState,
    dispatch
  } = useContext(store)

  // ?==========================
  // ?====== Destructing  ======
  // ?==========================
  const { pageContext, data } = props
  const { poweredImages } = data
  const configData = data.allConfigData.edges[0].node
  const businessData = data.allBusinessData.edges[0].node
  const { metaTitle, metaDescription, keywords } = configData
  const { name, desc, city, avatar, tags } = businessData

  const {
    // gonationID,
    pageObjectKey,
    // locationIndex,
    // city,
    // locations,
    isMultiLocationSite
  } = pageContext

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  // grabs the theme ui object for use
  const themeContext = useThemeUI().theme
  const pageTitle = pageObjectKey // `${customPageName !== '' ? customPageName : pageObjectKey}`

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  // ?============================================
  // ?====== Dynamic Font Loading Operators ======
  // ?============================================

  const googleFamilies = themeContext.fonts.googleFonts ? themeContext.fonts.googleFonts : ['Roboto'] // without passing anything it breaks

  const customFamilies = themeContext.fonts.customFamilies ? themeContext.fonts.customFamilies : ['']
  const customUrls = themeContext.fonts.customUrls ? themeContext.fonts.customUrls : ['']

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  useEffect(() => {
    dispatch({
      type: 'SET_SITE_DATA',
      data: {
        ...pageContext,
        businessData,
        configData,
        poweredImages,
        pageTitle,
        isMultiLocationSite
      }
    })

    if (typeof window !== `undefined`) {
      var WebFont = require('webfontloader')
      // uses the webfont loadder library to dynamically load the right fonts
      WebFont.load({
        google: {
          families: googleFamilies
        },
        custom: {
          families: customFamilies,
          urls: customUrls
        }
      })
    }

    return () => {}
  }, [])

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <>
      <SEO
        title={metaTitle ? `${metaTitle}` : `${name}`}
        description={metaDescription ? `${metaDescription}` : `${desc}`}
        favicon={avatar.imageBaseUrl + '/' + avatar.imagePrefix}
        keywords={keywords ? `${keywords}` : tags}
      />
      <MultiLocations businessData={data.allBusinessData.edges} configData={data.allConfigData.edges[0].node} />
    </>
  )
}

export default MultipleLocationTemplate

export const query = graphql`
  query MultipleLocationTemplateQuery {
    allBusinessData {
      edges {
        node {
          ...businessDataFields
        }
      }
    }
    allConfigData {
      edges {
        node {
          ...allConfigDataFields
        }
      }
    }
    poweredImages {
      ...poweredImagesData
    }
  }
`
