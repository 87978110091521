import React, { useState, useEffect } from 'react'

export default function MultiLocations({
  businessData,
  configData
  // options,
  // variantName,
}) {
  const [Version, setVersion] = useState(null) //version of the component is stored in state

  // dyanmically imports the right component to render depending on
  //  the version in the config provided
  const addComponent = async version => {
    await import(`./${version}/MutliLocations.js`)
      .then(component => setVersion(() => component.default)) // must set component as a function
      .catch(error => {
        console.error(`"${version}" not yet supported - ${error}`)
      })
  }

  useEffect(() => {
    addComponent(configData.multiSite.version) // runs onces on load
    return () => {}
  }, [])

  return (
    <div>
      {Version ? (
        <Version
          businessData={businessData}
          configData={configData}
          options={configData.multiSite.options}
          variantName={configData.multiSite.variantName}
        />
      ) : (
        ''
      )}
    </div>
  )
}
